/* src/components/ContainerBox/ContainerBox.css */

.container-box {
    width: 100%;
    background-color: rgba(0, 177, 181, 0.07);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* Ensure padding doesn’t add extra width */
}