/* VerticalInfoContainer.css */

/* Existing styles for desktop, unchanged */
.vertical-info-container-wrapper-for-brand {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 20px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 177, 181, 0.07);
    z-index: -1;
}

.vertical-info-container-heading-wrapper-for-brand {
    width: 100%;
    max-width: 1100px;
    text-align: left;
    margin-bottom: 40px;
    box-sizing: border-box;
}

.vertical-info-container-heading-for-brand {
    font-family: 'Oswald', sans-serif;
    font-size: 46px;
    letter-spacing: -0.04em;
    font-weight: 700;
    color: #00B1B5;
    margin-bottom: 80px;
    text-transform: uppercase;
}

.vertical-info-container-description-for-brand {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    letter-spacing: -0.04em;
    font-weight: 400;
    color: #000;
    line-height: 0.95;
    box-sizing: border-box;
}

.vertical-info-container-for-brand {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    justify-items: center;
    align-items: start;
    padding: 20px;
    max-width: 1000px;
    width: 100%;
    box-sizing: border-box;
}

/* Mobile Styles */
@media screen and (max-width: 1024px) {

    /* Adjust to target larger mobile devices */
    .vertical-info-container-wrapper-for-brand {
        padding: 40px 20px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .vertical-info-container-heading-wrapper-for-brand {
        max-width: 100%;
        text-align: left;
        /* Center the heading */
        padding-left: 0;
        box-sizing: border-box;
    }

    .vertical-info-container-heading-for-brand {
        font-size: 28px;
        text-align: left;
        /* Center heading text */
        margin: 0 auto 20px auto;
        padding-left: 0;
        max-width: 600px;
    }

    .vertical-info-container-description-for-brand {
        font-size: 16px;
        line-height: 1.4;
        text-align: left;
        /* Center the paragraph text */
        margin: 0 auto 20px auto;
        padding: 0 20px;
        max-width: 600px;
        box-sizing: border-box;
    }

    .vertical-info-container-for-brand {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* Center each card */
        justify-content: center;
        /* Align cards to the center */
        gap: 30px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .info-card {
        width: 100%;
        max-width: 600px;
        /* Set max-width to keep cards centered and aligned */
        box-sizing: border-box;
    }
}