.carousel-container-main {
    width: calc(100% - 50px);
    /* Reduces width by 10px on each side */
    max-width: 1320px;
    /* Reduced by 20px (10px each side) from original */
    padding: 20px 0;
    margin-top: 10px;
    margin: auto;
    padding-bottom: 100px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
}

.carousel-heading-main {
    font-family: 'Oswald', sans-serif;
    font-size: 60px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: -0.04em;
    line-height: 0.95;
}

.carousel-item-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    /* Adjust height based on your design */
    position: relative;
    transition: opacity 0.5s ease-in-out;
    /* Transition effect for fade */
    opacity: 0.2;
    /* Default opacity for non-active slides */
}

.carousel-item-main.active-slide {
    opacity: 1;
    transform: scale(1.05);
    /* Slightly scale the focused slide */
    z-index: 2;
    /* Bring focused slide to the front */
}

.carousel-image-main {
    width: 100%;
    max-width: 1000px;
    /* Set a maximum width for better centering */
    height: auto;
    object-fit: contain;
    margin-top: 20px;
    border-radius: 40px;
    /* Add shadow for effect */
}


/* Change the color of all the dots */
.slick-dots li button:before {
    color: #00B1B5 !important;
    /* Use your creator orange color */
    font-size: 12px !important;
    /* Adjust size if needed */
}

/* Highlight the active dot */
.slick-dots li.slick-active button:before {
    color: #00B1B5 !important;
    /* Ensures active dot also matches creator orange */
    opacity: 1 !important;
    /* Makes sure active dot is fully visible */
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .carousel-container-main {
        width: calc(100% - 30px);
        max-width: 100%;
        padding: 10px 0;
        margin: auto;
        margin-top: 20px;
        padding-bottom: 50px;
        box-sizing: border-box;
        overflow: hidden;
        /* Ensure nothing overflows out of the container */
    }

    .carousel-heading-main {
        font-size: 36px;
        margin-bottom: 15px;
        line-height: 1.1;
    }

    .carousel-item-main {
        height: auto;
        /* Adjust to allow the item to size naturally */
        width: 100%;
        min-height: 300px;
        /* Prevent collapse */
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        /* Ensure items are properly positioned */
    }

    .carousel-image-main {
        width: 100%;
        max-width: 100%;
        /* Ensure image does not exceed the container */
        height: auto;
        object-fit: contain;
        /* Ensure the entire image fits without being cropped */
        border-radius: 20px;
        margin-top: 10px;
        display: block;
        /* Ensure it's displayed properly */
    }

    .slick-dots li button:before {
        font-size: 10px !important;
    }
}