.privacy-policy-container {
    margin-top: 150px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 20px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .privacy-policy-container {
        margin-top: 150px;
        padding-left: 10px;
        padding-right: 5px;
        padding-bottom: 20px;

    }
}