.mission-statement-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
}

.mission-statement-wrapper>div {
    max-width: 1500px;
    /* Set a max width for the mission statement */
    width: 100%;
    /* Ensures it takes full width up to max-width */
}

.brands-wrapper {
    position: relative;
    /* Background color of the section */
    padding: 50px 20px;
    border-radius: 0 0 30px 30px;
    /* Rounded bottom corners */
    box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.2);
    /* Shadow effect */
    overflow: hidden;
    z-index: 1;
    /* Position it correctly in the stack */
    background-color: #fff;
}

.brands-wrapper::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 11;
    /* Place behind the wrapper content */
    border-radius: 0 0 30px 30px;
    /* Matches the bottom corners */
}

.video-section {
    flex-direction: row;
    flex: 1;
}

.info-container-home-content {
    background-color: rgba(0, 177, 181, 0.07);
}

.purpose-wrapper {
    background-color: #fef6ef;
    padding-top: 20px;
}


@media screen and (max-width: 768px) {

    .brands-wrapper,
    .mission-statement-wrapper {
        padding: 20px;
        /* Adjust padding for smaller devices */
    }

    :root {
        --creator-orange: #FF872F;
    }

    html,
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: 'Inter', sans-serif;
        overflow-x: hidden;
        /* Prevent horizontal scrolling */
    }

    /* Home Container to occupy full height and adjust accordingly */
    .home-container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* Start from top */
        align-items: center;
        box-sizing: border-box;

    }

    .hero-home-container {
        padding: 20px;
    }

    .info-container-home-content {
        background-color: rgba(0, 177, 181, 0.07);
        width: 100%;
        flex: 1
    }

    /* Mission Statement Wrapper */
    .mission-statement-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 20px;
        /* Adjust for mobile friendliness */
        box-sizing: border-box;
    }

    .mission-statement-wrapper>div {
        width: 100%;
        max-width: 1500px;
        /* Restrict maximum width */
    }



    /* Brands Wrapper */
    .brands-wrapper {
        position: relative;
        width: 100%;
        /* Adjust padding for mobile
        border-radius: 0 0 30px 30px;
        box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.2); */
        overflow: hidden;
        z-index: 1;
        box-sizing: border-box;
    }

    .brands-wrapper::after {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 40px;
        z-index: 0;
        border-radius: 0 0 30px 30px;
        background-color: #fff;
        /* Ensure the same color as background */
    }

    /* Video Section */
    .video-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0;
        box-sizing: border-box;
    }

    .video-section video {
        width: 100%;
        max-width: 600px;
        /* Restrict the max size for mobile */
        border-radius: 20px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    }

    /* Hero Heading for Mobile and Desktop */
    .hero-heading h1 {
        font-family: 'Oswald', sans-serif;
        font-weight: 600;
        font-size: clamp(30px, 8vw, 88px);
        /* Responsive font size */
        line-height: 0.95;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: #1D1C1C;
        margin: 20px 0;
        /* Reduce margin for mobile */
        text-align: center;
    }

    /* Hero Description */
    .hero-description {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: clamp(16px, 4vw, 24px);
        /* Responsive font size */
        letter-spacing: -0.04em;
        color: #1D1C1C;
        margin-top: 20px;
        text-align: center;
        max-width: 90%;
        padding: 0 15px;
        /* Ensure good spacing on mobile */
    }

    /* Hero Video Container */
    .hero-video-container {
        margin-top: 20px;
        /* Adjust spacing */
        width: 90%;
        max-width: 600px;
        /* Limit max width */
        position: relative;
        box-sizing: border-box;
    }

    .hero-video {
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    }

    /* Footer */
    .footer {
        width: 100%;
        padding: 20px;
        text-align: center;
        box-sizing: border-box;
    }
}