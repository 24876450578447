/* Wrapper for the entire component */
.vertical-info-container-wrapper-for-creator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 20px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 50px 50px;
}

/* Heading styles */
.vertical-info-container-heading-for-creator {
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    letter-spacing: -0.04em;
    font-weight: 600;
    color: #000;
    margin-bottom: 40px;
    margin-left: -100px;
    text-transform: uppercase;
    text-align: left;
    /* Align text to the left */
    width: 100%;
    /* Stretch heading across the container */
    padding-left: 20px;
    /* Add left padding */
    max-width: 1000px;
    /* Restrict the heading width */
    box-sizing: border-box;

}

/* Container for the InfoCards */
.vertical-info-container-for-creator {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 150px;
    /* 100px gap on each side for horizontal spacing (200px total) */
    grid-row-gap: 50px;
    /* 50px gap for vertical spacing (100px total) */
    justify-items: center;
    /* Center cards horizontally */
    align-items: center;
    /* Center cards vertically within each grid cell */
    padding: 20px;
    max-width: 1000px;
    /* Restrict max width */
    width: 100%;
    box-sizing: border-box;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .vertical-info-container-wrapper-for-creator {
        padding: 40px 20px;
        /* Adjust padding for mobile */
        width: 100%;
        box-sizing: border-box;
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
        /* Reduce box-shadow for a softer effect on smaller screens */
        border-radius: 0 0 30px 30px;
        /* Reduce border radius for smaller devices */
    }

    .vertical-info-container-heading-for-creator {
        font-size: 20px;
        /* Reduce heading size for mobile */
        text-align: left;
        /* Keep left-aligned heading */
        margin-left: 0;
        /* Remove left margin for mobile alignment */
        margin-bottom: 20px;
        /* Adjust spacing between heading and cards */
        padding-left: 10px;
        /* Add slight padding for better alignment */
    }

    .vertical-info-container-for-creator {
        display: flex;
        flex-direction: column;
        /* Switch to a vertical stack for the cards */
        align-items: flex-start;
        /* Align cards to the left */
        gap: 30px;
        /* Add spacing between each card */
        width: 100%;
        /* Take the full width */
        padding: 0 10px;
        /* Add padding for better appearance */
        box-sizing: border-box;
    }

    .info-card {
        width: 100%;
        max-width: calc(100% - 20px);
        /* Keep cards full-width with slight padding */
        box-sizing: border-box;
    }
}