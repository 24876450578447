/* Wrapper for the MessageBox */
.message-box-container {
    background-color: #1c1c1c;
    /* Dark background */
    color: #ffffff;
    /* White text color */
    padding: 60px 0px;
    /* Adjust padding for spacing */
    border-radius: 50px;
    /* Rounded corners */
    max-width: 1500px;
    /* Limit the width */
    width: calc(100% - 400px);
    /* Adjust width for responsiveness */
    margin: 0 auto;
    /* Center the container */
    font-family: 'Inter', sans-serif;
    /* Font family for the text */
    font-size: 24px;
    /* Font size */
    line-height: 0.95;
    /* Increase line height for better readability */
    letter-spacing: -0.04em;
    /* Slight letter-spacing */
    font-weight: 400;
    /* Normal font weight */
    text-align: left;
    /* Left-align text */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* Optional: subtle shadow */
}

/* Highlighted text styles */
.highlight {
    font-weight: 700;
    /* Bold for emphasis */
    color: #FF872F;
    /* Highlight color */
}

.message-text {
    padding: 10px 100px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .message-box-container {
        padding: 30px 20px;
        /* Reduced padding to fit on smaller screens */
        border-radius: 30px;
        /* Reduce border-radius for a more subtle effect on smaller devices */
        max-width: 90%;
        /* Take up most of the available width while leaving some margin */
        width: calc(100% - 40px);
        /* Ensure consistent width for small devices */
        margin: 20px auto;
        /* Add a bit of vertical margin for spacing */
        font-size: 18px;
        /* Decrease font size for readability on mobile */
        line-height: 1.2;
        /* Adjust line height for readability */
    }

    .message-text {
        padding: 0 20px;
        /* Reduce horizontal padding for smaller screens */
    }
}