.our-purpose-container {
    background-color: #202020;
    color: #ffffff;
    text-align: left;
    padding: 200px 150px 200px 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
}

.text-content-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
}

.text-content {
    max-width: 60%;
}

.our-purpose-heading {
    font-family: 'Oswald', sans-serif;
    font-size: 60px;
    letter-spacing: -0.02em;
    line-height: 0.95;
    margin-bottom: 30px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    max-width: 600px;
}

.highlight {
    color: var(--creator-orange);
}

.purpose-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -0.02em;
    line-height: 0.95;
    text-align: left;
    max-width: 100%;
    margin-bottom: 20px;
}

.connect-button-container {
    display: flex;
    justify-content: flex-start;
    padding-top: 30px;
    margin-top: 50px;
}

.connect-button {
    font-family: 'Oswald', sans-serif;
    background-color: var(--creator-orange);
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 12px 30px;
    letter-spacing: -0.04em;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.connect-button:hover {
    background-color: #e07b36;
}

.impact-partners-wrapper {
    flex: 1;
    align-self: flex-start;
    max-width: 40%;
    display: flex;
    justify-content: center;
}

.impact-partners-container {
    text-align: center;
    color: #ffffff;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

@media screen and (max-width: 768px) {
    .our-purpose-container {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding: 160px 40px;
    }

    .text-content-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }

    .text-content {
        max-width: 100%;
    }

    .our-purpose-heading {
        font-size: 36px;
        margin-bottom: 40px;
    }

    .purpose-text {
        font-size: 18px;
        line-height: 0.95;
        margin-bottom: 15px;
        text-align: left;
    }

    .connect-button-container {
        justify-content: center;
        margin-top: 30px;
    }

    .connect-button {
        width: 300px;
        padding: 2px 30px;
        border-radius: 30px;
    }

    .impact-partners-wrapper {
        margin-top: 50px;
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .impact-partners-container {
        align-items: center;
    }
}