/* General styles for MissionStatement */
.container {
    background-color: #1c1c1c;
    color: #ffffff;
    padding: 100px;
    border-radius: 50px;
    max-width: 1500px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    line-height: 0.95;
    letter-spacing: -0.04em;
    font-weight: 400;
    text-align: left;
    margin-left: 100px;
    margin-right: 100px;
    /* Ensures text is left-aligned */
}

.highlight {
    font-weight: 700;
    /* Bold weight for highlighted text */
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .container {
        padding: 60px 60px;
        border-radius: 20px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 16px;
        line-height: 0.95;
        max-width: 100%;
        /* Make it take the full width of the screen */
    }
}