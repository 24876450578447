/* General styles for Footer */
.footer {
  color: #fff;
  padding: 20px 10px 10px 10px;
  background-color: #202020;
  width: calc(100% - 100px);
  /* Align with the navbar width */
  margin: 0 auto;
  /* Center the footer */
  max-width: 1500px;
  /* Set a maximum width to keep the design contained */
}

/* Footer Top Section */
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 100px;
  background-color: #202020;
  width: 100%;
  /* Ensures it adapts to the container width */
  box-sizing: border-box;
  /* Include padding in width calculation */
}

/* Footer Left Section */
.footer-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.footer-email {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.footer-social {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.social-icon {
  width: 27px;
  height: 27px;
  object-fit: contain;
}

.social-icon-whatsapp {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

/* Popup Styles */
.popup-container {
  position: relative;
  display: inline-block;
}

.popup {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  bottom: 40px;
  left: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
  font-family: 'Inter', sans-serif;
  animation: bounceIn 0.5s ease;
}

.popup::after {
  content: '';
  position: absolute;
  bottom: -22px;
  left: 0px;
  border-width: 12px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.popup a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 8px 10px;
  border-radius: 5px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.popup a:hover {
  background-color: #444;
  color: #fff;
}

.social-icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  70% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

/* Footer Right Section */
.footer-right {
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}

.footer-image {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  object-fit: cover;
}

/* Footer Bottom Section */
.footer-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px 100px;
  gap: 40px;
  background-color: #202020;
  width: 100%;
  /* Ensures it adapts to the container width */
  box-sizing: border-box;
}

/* Footer Logo Section */
.footer-logo {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.footer-logo img {
  width: 260px;
  height: auto;
}

/* Footer Column Styles */
.footer-column {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  margin-left: 100px;
}

.footer-idividual-column {
  flex: 1;
  min-width: 150px;
}

.footer-idividual-column h4 {
  margin-bottom: 40px;
  font-size: 21px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.04em;
  text-align: left;
  font-weight: 400;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-column li {
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.04em;
  text-align: left;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-idividual-column:last-child {
  align-items: flex-start;
}

.footer-link:hover {
  color: var(--creator-orange);
  transform: scale(1.05);
}

/* Footer Copyright Section */
.footer-copyright {
  text-align: center;
  font-size: 0.8rem;
  padding-top: 10px;
  padding-left: 100px;
  padding-bottom: 20px;
  text-align: left;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .footer {
    padding: 10px;
    width: calc(100% - 20px);
    /* Set width with slight margin for mobile */
    margin: 0 auto;
    /* Center it */
    max-width: 100%;
    /* Allow full width for mobile */
  }

  .footer-top {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
  }

  .footer-left {
    align-items: flex-start;
    text-align: left;
    gap: 15px;
  }

  .footer-email {
    font-size: 1.5rem;
    text-align: left;
  }

  .footer-social {
    justify-content: flex-start;
  }

  .footer-right {
    margin-top: 20px;
    padding: 0;
    max-width: 80%;
    text-align: left;
  }

  .footer-image {
    width: 100%;
    max-width: 200px;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    text-align: left;
  }

  .footer-logo {
    text-align: left;
  }

  .footer-column {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 0;
    width: 100%;
    text-align: left;
  }

  .footer-idividual-column {
    flex: 1;
    min-width: calc(50% - 20px);
    text-align: left;
  }

  .footer-idividual-column:nth-child(2) {
    order: 1;
  }

  .footer-idividual-column:nth-child(1) {
    order: 2;
  }

  .footer-idividual-column:nth-child(3) {
    order: 3;
  }

  .footer-idividual-column h4 {
    margin-bottom: 30px;
    font-size: 18px;
  }

  .footer-column ul {
    text-align: left;
  }

  .footer-column li {
    margin-bottom: 15px;
    text-align: left;
  }

  .footer-copyright {
    text-align: left;
    padding: 10px 20px;
  }

  .footer-logo-mobile {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .footer-logo-mobile img {
    width: 150px;
    height: auto;
  }
}