.brand-hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0;
    background-size: cover;
    background-position: center;
    text-align: center;
    overflow: hidden;
    width: 100%;
    /* Full-screen height to keep everything centered */
    padding-top: 200px;
}

.brand-hero-overlay {
    display: flex;
    /* Use flexbox to align items */
    flex-direction: column;
    /* Stack text above the video */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* Center items horizontally */
    width: 100%;
    height: 100%;
    position: relative;
}

.brand-hero-text {
    flex: 0 1 auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 60%;
    text-align: center;
    margin: 0 auto;
    gap: 10px;
}

.brand-hero-text h1 {
    font-family: 'Oswald', sans-serif;
    font-weight: 800;
    font-size: 80px;
    line-height: 0.95;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: #1D1C1C;
    margin: 0;
    text-align: center;
}

.first-line,
.second-line {
    display: block;
    white-space: nowrap;
}

.changing-word-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.2em;
    line-height: 0.95;
    text-align: center;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 50px;
}

.changing-word {
    color: #1D1C1C;
    font-family: 'Oswald', sans-serif;
    font-weight: 800;
    font-size: 80px;
    line-height: 0.95;
    letter-spacing: -0.04em;
    text-align: center;
    display: inline-block;
    position: absolute;
}

/* Video Container */
.brand-hero-video-container {
    margin-top: 50px;
    /* Reduced margin to bring video closer */
    width: 80%;
    max-width: 1000px;
    position: relative;
    display: flex;
    justify-content: center;
    /* Center the video horizontally */
    align-items: center;
    /* Center the video vertically */
}

/* Hero Video */
.brand-hero-video {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    display: block;
}

/* Mobile Styles with Dynamic Font Sizing */
@media screen and (max-width: 768px) {
    .brand-hero {
        padding-top: 120px;
        padding-bottom: 80px;
    }

    .brand-hero-overlay {
        padding: 20px;
        text-align: center;
    }

    .brand-hero-text {
        padding: 0 20px;
        gap: 15px;
        /* Increase spacing for readability */
    }

    .brand-hero-text h1 {
        font-size: clamp(20px, 6vw, 40px);
        /* Dynamic font size between 24px and 32px */
        line-height: 0.95;
        word-break: break-word;
        /* Ensure the text breaks properly */
        letter-spacing: -0.04em;
        /* Adjusted to make it more legible */
    }

    .changing-word-container {
        height: auto;
        margin-top: 0px;
        margin-bottom: 20px;

        /* Adjust for better spacing */
    }

    .changing-word {
        font-size: clamp(20px, 6vw, 40px);
        /* Dynamic font size between 20px and 28px */
        line-height: 0.95;
    }

    .brand-hero-video-container {
        width: 100%;
        margin-top: 20px;
        /* Decrease spacing to better align components */
    }

    .brand-hero-video {
        border-radius: 10px;
        width: 95%;
        /* Ensure the video takes most of the width without touching the edges */
    }
}