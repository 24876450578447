/* General styles for ContentSections */
.section-container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
}

.section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
    padding: 50px 0;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
}

.section-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    flex: 1;
    max-width: 55%;
    box-sizing: border-box;
}

.section h3 {
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 15px;
    letter-spacing: -0.04em;
}

.section h2 {
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    font-size: 46px;
    margin-top: 5px;
    margin-bottom: 25px;
    line-height: 0.95;
    letter-spacing: -0.04em;
    color: #3ebbbd;
}

.section p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 0.95;
    margin-top: 10px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #000;
}

.learn-more-button {
    align-self: flex-start;
    margin-top: 20px;
    padding: 5px 35px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: -0.04em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    width: 221px;
}

.learn-more-button:hover {
    background-color: var(--hover-color);
}

.section-image-container {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
}

.section-image-outline {
    position: relative;
    width: 424px;
    height: 424px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-image-left,
.section-image-right {
    width: 394px;
    height: 394px;
    position: relative;
    transform: translate(40px, 40px);
    z-index: 2;
}

.section-image-right {
    transform: translate(-70px, 40px);
}

.section-image-outline::after {
    content: "";
    position: absolute;
    width: 351px;
    height: 351px;
    border: 1px solid;
    border-radius: 40px;
    top: 0;
    left: 0;
    z-index: 1;
}

.left-outline::after {
    border-color: #FF872F;
}

.right-outline::after {
    content: "";
    position: absolute;
    width: 351px;
    height: 351px;
    border: 1px solid;
    border-radius: 40px;
    top: 0;
    left: 40px;
    z-index: 1;
    border-color: #00b1b5;
}

.section-image-left img,
.section-image-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 40px;
    position: relative;
    z-index: 2;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .section-container {
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .section {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0;
        gap: 30px;
    }

    .section-content {
        max-width: 100%;
        align-items: flex-start;
        text-align: left;
    }

    .section h3 {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .section h2 {
        font-size: 30px;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .section p {
        font-size: 16px;
        line-height: 0.95;
        margin-top: 10px;
        margin-bottom: 60px;
    }

    .learn-more-button {
        width: calc(100% - 20px);
        /* Make button take full width minus 20px padding on each side */
        font-size: 18px;
        padding: 5px 0px;
        margin: 0 auto;
        /* Center button */
    }

    .section-image-container {
        margin-top: 20px;
        width: 100%;
        justify-content: center;
    }

    .section-image-outline {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        max-width: 300px;
        max-height: 300px;
    }

    .section-image-left,
    .section-image-right {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        max-width: 280px;
        max-height: 280px;
        transform: translate(3vw, 3vw);
    }

    .section-image-outline::after {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        max-width: 280px;
        max-height: 280px;
    }

    .left-outline::after,
    .right-outline::after {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        max-width: 280px;
        max-height: 280px;
        top: -3vw;
        left: -3vw;
    }
}