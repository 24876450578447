.creator-hero {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    padding: 100px 20px;
    box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 50px 50px;
    margin-bottom: -50px;
}

.image-placeholder {
    width: 100%;
    height: 300px;
    /* Match your expected image height */
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
}

/* Hero Heading */
.creator-hero-heading h1 {
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    font-size: 60px;
    line-height: 0.95;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: #1D1C1C;
    margin-top: 100px;
    text-align: center;
}

/* Hero Description */
.creator-hero-description {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -0.04em;
    line-height: 0.95;
    color: #1D1C1C;
    margin-top: 50px;
    max-width: 900px;
    text-align: center;
}

.creator-login-button-container {
    justify-content: center;
    /* Align button to the right */
    width: 100%;
    padding-top: 60px;
}

.creator-login-button-home {
    font-family: 'Oswald', sans-serif;
    background-color: #FF872F;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 2px 30px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: -0.04em;
    transition: background-color 0.3s ease;
}

.creator-login-button-home:hover {
    background-color: #fb7f27;
    /* Slightly darker shade on hover */
}

.creator-hero-image-wrapper {
    margin-top: 50px;
    width: 100%;
    max-width: 1200px;
    border-radius: 15px;
    overflow: hidden;
}

/* Hero image styles */
.creator-hero-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
    object-fit: cover;
}

/* Community Section */
.creator-hero-community {
    margin-top: 40px;
    /* Spacing from the image */
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    letter-spacing: -0.04;
    color: #1D1C1C;
    line-height: 0.95;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.community-highlight {
    color: #FF872F;
    font-weight: 600;
}

/* Button Container */
.community-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px
}

/* Button Styles */
.community-button {
    background-color: #25D366;
    /* WhatsApp green */
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 18px;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    letter-spacing: -0.04em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    max-width: 300px;
    /* Optional: limit the button size */
}

.community-button:hover {
    background-color: #20B954;
    /* Darker green on hover */
}

/* Image Inside the Button */
.community-button-image {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .creator-hero {
        padding: 140px 20px;
        text-align: center;
    }

    .creator-hero-heading h1 {
        font-size: 30px;
        line-height: 0.95;
        margin-top: 0;
    }

    .creator-hero-description {
        font-size: 16px;
        margin-top: 20px;
        max-width: 100%;
    }

    .creator-login-button-container {
        margin-top: 40px;
        justify-content: center;
    }

    .creator-login-button-home {
        font-size: 18px;
        padding: 5px 50px;
    }

    .creator-hero-image-wrapper {
        margin-top: 30px;
    }

    .creator-hero-image {
        max-width: 90%;
        border-radius: 15px;
    }

    .creator-hero-community {
        font-size: 16px;
        margin-top: 30px;
        line-height: 0.95;
    }

    .community-button-container {
        margin-top: 100px;
        margin-bottom: 5px;
    }

    .community-button {
        font-size: 18px;
        padding: 5px 50px;
    }
}