/* General Styles */
.brands-carousel-section {
  text-align: center;
  padding: 50px 20px;
  z-index: 1;
}

.brands-heading {
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.04em;
  color: #1D1C1C;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  /* Make sure it's centered */
  padding: 0;
  /* Remove padding to touch the edges */
}

.carousel-container::before,
.carousel-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 1;
  pointer-events: none;
}

.carousel-container::before {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.carousel-container::after {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.carousel-track {
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.carousel-item {
  flex: none;
  width: 75px;
  height: 75px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item img {
  width: 75px;
  height: 75px;
  object-fit: scale-down;
}

.connect-button-container-home {
  justify-content: center;
  width: 100%;
  padding-top: 40px;
  display: flex;
  box-sizing: border-box;
  /* Include padding in total width calculation */
}

.connect-button-home {
  font-family: 'Oswald', sans-serif;
  background-color: #00B1B5;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 2px 30px;
  /* Increase padding for larger button */
  font-size: 24px;
  /* Increase font size for more visibility */
  font-weight: bold;
  cursor: pointer;
  letter-spacing: -0.04em;
  transition: background-color 0.3s ease;
  max-width: 90%;
  /* Prevent button from overflowing */
  box-sizing: border-box;
}

.connect-button-home:hover {
  background-color: #02abae;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .brands-carousel-section {
    padding: 20px 0px;
    /* Reduced padding for mobile */
  }

  .brands-heading {
    font-size: 18px;
    margin-bottom: 15px;
    /* Slightly reduce margin-bottom */
  }

  .carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0;
  }

  .carousel-item {
    width: 60px;
    height: 60px;
    padding: 5px;
    /* Reduced padding */
  }

  .carousel-item img {
    width: 60px;
    height: 60px;
  }

  .connect-button-container-home {
    padding-top: 20px;
    padding: 40px 10px 0;
    display: flex;
    justify-content: center;
    /* Center the button */
    background-color: transparent;
    /* Remove red background */
  }

  .connect-button-home {
    font-size: 20px;
    /* Smaller font size for mobile */
    padding: 4px 70px;
    /* Adjust padding */
    border-radius: 20px;
    max-width: 100%;
    /* Prevent overflow on mobile screens */
  }
}