.impact-partners-container {
    margin-top: -10px;
    text-align: center;
    background-color: #202020;
    color: #ffffff;
    max-width: 1500px;
    /* Set max-width */
    width: calc(100% - 100px);
    /* Ensure it fits well within the screen width */
    margin-left: auto;
    /* Center container */
    margin-right: auto;
    /* Center container */
    padding: 40px 50px;
    /* Add padding for consistency */
}

.impact-partners-heading {
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.impact-partners-logos-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Space between each row of logos */
}

.impact-partners-row {
    display: flex;
    flex-direction: row;
    gap: 0;
    /* Remove any gap between logos */
    justify-content: center;
    align-items: center;
}

.impact-logo {
    width: auto;
    height: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 5px;
    /* Add padding if needed */
}

.impact-logo img {
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
}