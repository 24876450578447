.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 100px;
}

/* Hero Heading */
.hero-heading h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  font-size: 88px;
  /* Original size for desktop */
  line-height: 0.95;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #1D1C1C;
  margin-top: 100px;
  text-align: center;
  /* Center text for mobile */
}

/* Hero Description */
.hero-description {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 24px;
  /* Original size for desktop */
  letter-spacing: -0.04em;
  line-height: 0.95;
  color: #1D1C1C;
  margin-top: 45px;
  max-width: 1200px;
  text-align: center;
  /* Center description for better mobile experience */
  padding: 0 20px;
  /* Add padding to avoid text touching screen edges */
}

/* Video Container */
.hero-video-container {
  margin-top: 50px;
  /* Adjust spacing as needed */
  width: 80%;
  max-width: 1000px;
  position: relative;
}

/* Hero Video */
.hero-video {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  display: block;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .hero {
    padding-top: 100px;
    width: 100%;
    /* Reduce padding for mobile */
  }

  .hero-heading h1 {
    font-size: 40px;
    /* Reduce font size for mobile */
    line-height: 0.95;
    margin-top: 50px;
    /* Reduce top margin for mobile */
  }

  .hero-description {
    font-size: 18px;
    /* Reduce font size for mobile */
    margin-top: 25px;
    /* Reduce space between heading and description */
    max-width: 90%;
    /* Ensure the description doesn't exceed the viewport */
    padding: 0 15px;
    /* Add more padding for mobile to ensure comfortable reading */
  }

  .hero-video-container {
    margin-top: 30px;
    width: 100%;
    /* Make the container take up the full width */
    max-width: none;
    /* Remove any restriction on max width */
    position: relative;
    /* Ensure that the video can expand within this container */
  }

  .hero-video {
    width: 100%;
    /* Make the video fill the entire container width */
    height: auto;
    /* Maintain the aspect ratio automatically */
    max-height: 500px;
    /* Restrict the max height if needed to fit your design */
    object-fit: cover;
    /* Crop the video to fill the container as needed */
    border-radius: 15px;
    /* Keep rounded corners */
    /* Softer shadow effect */
  }

}