/* MessageBox.css */

.message-box-container-for-brands {
    background-color: #1c1c1c;
    /* Dark background */
    color: #ffffff;
    /* White text color */
    padding: 60px 40px;
    /* Adjust padding for spacing */
    border-radius: 50px;
    /* Rounded corners */
    max-width: 1500px;
    /* Limit the width */
    width: calc(100% - 500px);
    /* Adjust width for responsiveness */
    margin: 0 auto;
    /* Center the container */
    font-family: 'Inter', sans-serif;
    /* Font family for the text */
    font-size: 24px;
    /* Font size */
    line-height: 0.95;
    /* Increase line height for better readability */
    letter-spacing: -0.04em;
    /* Slight letter-spacing */
    font-weight: 400;
    /* Normal font weight */
    text-align: left;
    /* Left-align text */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* Optional: subtle shadow */
    margin-bottom: 100px;
    /* Bottom margin */
}

.highlight-for-brands {
    font-weight: 700;
    /* Bold for emphasis */
    color: #00B1B5;
    /* Highlight color */
}

.message-text-for-brands {
    padding: 10px 100px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .message-box-container-for-brands {
        padding: 30px 20px;
        /* Reduced padding to fit on smaller screens */
        border-radius: 30px;
        /* Reduced border radius for a more subtle effect on smaller devices */
        margin-left: 10px;
        margin-right: 10px;
        /* Adjusted margins to reduce side spacing for mobile */
        max-width: calc(100% - 20px);
        /* Ensure the container takes up most of the screen width */
        width: calc(100% - 20px);
        /* Make the width flexible for mobile */
        font-size: 18px;
        /* Decreased font size for better readability on mobile */
        line-height: 1.4;
        /* Increased line height for better readability */
        margin-bottom: 50px;
        /* Reduced bottom margin for mobile spacing */
    }

    .message-text-for-brands {
        padding: 0 10px;
        /* Reduced padding for mobile view to prevent text from being squished */
    }

    .highlight-for-brands {
        font-size: 18px;
        /* Adjusted the font size for highlights to fit better on mobile screens */
    }
}