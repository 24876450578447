/* Navbar Background */
.navbar-background {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 100px;
  border-radius: 20px;
  z-index: 90;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  /* Safari support */
  background-color: rgba(255, 255, 255, 0.6);
  transition: backdrop-filter 0.5s ease, background-color 0.5s ease;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background-color: #F5F1E8;
  border-radius: 20px;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  /* Space between logo and nav links */
  align-items: center;
  /* Vertically align content */
  box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

/* Logo Container */
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  /* Match the height of navbar */
}

.logo-image {
  width: 185px;
  /* Set the desired width */
  height: 28px;
  /* Set the desired height */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.logo:hover .logo-image {
  transform: scale(1.1);
  opacity: 0.8;
}


/* Navigation Container */
.nav-container {
  display: flex;
  justify-content: flex-end;
  /* Align nav links and button to the right */
  align-items: center;
  gap: 40px;
  /* Space between links and login button */
}

/* Navigation Links */
.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* Align vertically */
  gap: 50px;
  /* Reduce space between links */
  margin: 0;
  padding: 0;
}

.nav-links li {
  white-space: nowrap;
  /* Ensure text stays on one line */
}

.nav-button {
  background: none;
  border: none;
  padding: 8px 10px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.04em;
  cursor: pointer;
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative;
}

.nav-button::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: var(--creator-orange);
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-button:hover {
  color: var(--creator-orange);
}

.nav-button:hover::after {
  width: 70%;
  left: 15%;
  /* Adjust left position to center the underline */
}

/* Login Button */
.login-button {
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 50px;
  background-color: black;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  letter-spacing: -0.04em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.login-button:hover {
  background-color: white;
  color: black;
}

/* Mobile Navbar Background */
.mobile-navbar-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  backdrop-filter: blur(10px);
  z-index: 99;
  pointer-events: none;
  /* Allow clicks to pass through */
}

/* Mobile Navbar */
.mobile-navbar {
  display: none;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 500px;
  background-color: #F5F1E8;
  border-radius: 30px;
  padding: 20px 10px 15px 10px;
  margin-top: 20px;
  box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.2);
  z-index: 200;
}

.mobile-navbar-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}

.mobile-menu-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  right: 20px;
}

/* Mobile Navbar Links */
.mobile-nav-links {
  background-color: #F5F1E8;
  padding: 20px;
  border-radius: 15px;
  margin-top: 15px;
}

.mobile-nav-links ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.mobile-nav-links li {
  margin-bottom: 15px;
}

.mobile-nav-links a {
  text-decoration: none;
  color: black;
  font-size: 16px;
}

/* Mobile View Adjustments */
@media screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .mobile-navbar {
    display: block;
  }

  .logo-image {
    max-height: 50px;
  }
}

@media screen and (max-width: 1024px) {

  .nav-container {
    gap: 0px;
  }

  /* Navigation Links */
  .nav-links {
    gap: 0px;
  }
}