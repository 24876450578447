/* General styles for PurposeSection */
.purpose-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fef6ef;
    padding: 60px 40px;
    gap: 40px;
    border-radius: 20px;
    position: relative;
    max-width: 1500px;
    /* Added max-width for consistency */
    width: calc(100% - 10px);
    /* Align with navbar width */
    margin: 0 auto;
    /* Center the container */
    box-sizing: border-box;
    /* Include padding in width calculation */
}

.purpose-content {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-left: 40px;
}

.purpose-heading {
    font-family: 'Oswald', sans-serif;
    font-size: 46px;
    font-weight: 600;
    color: #f68e3d;
    letter-spacing: -0.04em;
    margin: 0;
    text-align: left;
}

.purpose-description {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #5a5a5a;
    line-height: 0.95;
    letter-spacing: -0.04em;
    text-align: left;
}

.purpose-description span {
    color: #f68e3d;
    font-weight: 600;
    letter-spacing: -0.04em;
}

.purpose-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #5a5a5a;
    line-height: 0.95;
    letter-spacing: -0.04em;
    text-align: left;
}

.purpose-list li {
    margin-bottom: 15px;
}

.purpose-button {
    align-self: flex-start;
    padding: 10px 30px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.purpose-button:hover {
    background-color: #333;
}

.purpose-image-wrapper {
    max-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 100px;
    z-index: 1;
}

.purpose-image-wrapper::before {
    content: "";
    position: absolute;
    bottom: 55px;
    left: 65px;
    width: 436px;
    height: 311px;
    border: 1px solid #FF872F;
    border-radius: 40px;
    z-index: 0;
    background-color: transparent;
}

.purpose-image {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    z-index: 1;
}

/* Mobile styles */
@media (max-width: 768px) {
    .purpose-section {
        flex-direction: column;
        text-align: left;
        padding: 60px 20px;
        gap: 30px;
        width: calc(100% - 40px);
        /* Adjust width for mobile */
        margin: 0 auto;
        /* Center container on mobile */
    }

    .purpose-image-wrapper {
        order: 1;
        /* Ensure image appears first */
        width: 100%;
        max-width: 90vw;
        justify-content: flex-start;
        margin: 0 auto 20px auto;
        /* Center image */
    }

    .purpose-image {
        width: 100%;
        height: auto;
        max-width: calc(100% - 20px);
        border-radius: 20px;
        object-fit: cover;
    }

    .purpose-image-wrapper::before {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        position: absolute;
        bottom: 15vw;
        left: 50vw;
        border: 1px solid #FF872F;
        border-radius: 40px;
        z-index: 0;
    }

    .purpose-content {
        order: 2;
        /* Ensure content appears second */
        max-width: 100%;
        align-items: flex-start;
        margin-left: -10px;
    }

    .purpose-heading {
        text-align: left;
    }

    .purpose-description {
        text-align: left;
    }

    .purpose-list {
        text-align: left;
        margin-left: 30px;
        margin-bottom: 20px;
    }

    .purpose-button {
        width: calc(100% - 40px);
        padding: 10px 0;
        margin: 0 auto;
    }
}