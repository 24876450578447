/* Privacy Section Styles */
.privacy-section {
    padding-bottom: 10px;
    margin-left: 100px;
    margin-right: 100px;
}

/* Heading Styles */
.privacy-heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
}

.privacy-subheading {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    margin-bottom: 10px;
    text-align: left;
}

.privacy-info {
    font-size: 16px;
    color: #444;
    text-align: left;
    padding-bottom: 20px;
}

/* Main Info Styles */
.privacy-main-info {
    font-size: 20px;
    font-weight: 600;
    color: #444;
    line-height: 0.95;
    text-align: left;
}

.privacy-main-heading {
    font-size: 40px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
}

/* Table Container for Scrollable Table */
.privacy-table-container {
    overflow-x: auto;
    /* Allows horizontal scrolling for tables */
    width: 100%;
    margin: 20px 0;
}

/* Privacy Table Styles */
.privacy-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    font-size: 16px;
    text-align: left;
}

.privacy-table th,
.privacy-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
}

.privacy-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #333;
}

.privacy-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.privacy-table tr:hover {
    background-color: #f1f1f1;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    /* Adjustments specifically for mobile screens */

    .privacy-section {
        width: 100%;
        max-width: 90%;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 10px;
    }

    .privacy-main-heading {
        font-size: 28px;
        margin-bottom: 15px;
        line-height: 1.2;
        text-align: left;
    }

    .privacy-main-info {
        font-size: 18px;
        font-weight: 600;
        color: #444;
        line-height: 1.5;
        text-align: left;
        margin-bottom: 20px;
    }

    .privacy-heading {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        margin-bottom: 15px;
        text-align: left;
    }

    .privacy-subheading {
        font-size: 16px;
        font-weight: 600;
        color: #666;
        margin-bottom: 10px;
        text-align: left;
    }

    .privacy-info {
        font-size: 14px;
        color: #444;
        text-align: left;
        line-height: 1.5;
        padding-bottom: 15px;
    }

    .privacy-table-container {
        padding: 10px;
        /* Add padding to prevent the table from touching the edges */
    }

    .privacy-table {
        font-size: 14px;
        margin: 10px 0;
    }

    .privacy-table th,
    .privacy-table td {
        padding: 8px 10px;
    }

    .privacy-policy-container {
        margin-top: 150px;
        padding-left: 10px;
        padding-right: 5px;
        padding-bottom: 20px;
    }
}