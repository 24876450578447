/* src/components/ContentEquation/ContentEquation.css */

.container-content {
    padding: 100px;
    margin-left: 100px;
    margin-right: 100px;
}

.content-equation {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    gap: 20px;
    /* Constrain width to prevent stretching */
    padding: 50px;
    border: 1px solid #00B1B5;


}

.content-item {
    font-family: 'Oswald', sans-serif;
    font-size: 50px;
    font-weight: 600;
    color: #00B1B5;
    line-height: 0.95;
    letter-spacing: -0.04em;
    white-space: wrap;
    max-width: 30%;
}

.content-symbol {
    font-family: 'Oswald', sans-serif;
    font-size: 60px;
    font-weight: 600;
    color: #00B1B5;
    line-height: 0.95;
    letter-spacing: -0.04em;
    white-space: wrap;
    margin-left: 50px;
    margin-right: 50px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .container-content {
        padding: 20px;
        margin: 0 20px;
        box-sizing: border-box;
    }

    .content-equation {
        flex-direction: column;
        /* Stack items vertically */
        align-items: center;
        /* Center align items vertically */
        padding: 20px;
        gap: 15px;
        /* Reduce spacing for a balanced look */
        border: 1px solid #00B1B5;
    }

    .content-item {
        font-size: 24px;
        /* Reduce font size for better readability on mobile */
        max-width: 100%;
        /* Take full width */
        text-align: center;
        /* Ensure text is centered */
        margin: 10px 0;
        /* Add margin to separate each item */
    }

    .content-symbol {
        font-size: 28px;
        /* Reduce symbol size */
        margin: 5px 0;
        /* Adjust margin to keep spacing consistent */
    }
}