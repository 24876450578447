/* General Styles for InfoContainer */
.info-container-wrapper-home {
    padding: 80px 80px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -50px;
    max-width: 1500px;
    /* Set the same max-width as your nav bar */
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

/* InfoContainer for desktop */
.info-container-home {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

/* Heading Styles */
.info-container-heading-home {
    font-family: 'Oswald', sans-serif;
    font-size: 46px;
    letter-spacing: -0.04em;
    font-weight: 600;
    color: #00B1B5;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: left;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .info-container-wrapper-home {
        padding: 40px 0px;
        /* Remove left and right padding for full width */
        margin-top: -20px;
        width: 100%;
        /* Ensure it takes the full width on mobile */
        box-sizing: border-box;
        /* Include padding in width calculation */
    }

    .info-container-heading-home {
        font-size: 24px;
        /* Reduce heading size for mobile */
        text-align: left;
        /* Keep left-aligned heading */
        margin-bottom: 30px;
        padding-left: 20px;
        /* Add some padding to prevent it from sticking to the left edge */
    }

    .info-container-home {
        display: flex;
        flex-direction: column;
        /* Stack the cards vertically */
        align-items: center;
        /* Center cards horizontally */
        width: 100%;
        /* Take the full width */
        box-sizing: border-box;
        /* Include padding in width calculation */
        padding: 0;
    }
}