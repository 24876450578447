/* General styles for LetsTalk */
body {
    font-family: 'Inter', sans-serif;
}

.lets-talk-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 200px 100px 100px 100px;
    max-width: 1500px;
    /* Set max-width to 1500px */
    width: calc(100% - 200px);
    /* Align with navbar width */
    margin: 0 auto;
    /* Center the container */
    box-sizing: border-box;
}

.contact-info-container {
    flex: 1;
    margin-right: 40px;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
}

.lets-talk-heading {
    font-family: 'Oswald', sans-serif;
    color: #1a1a1a;
    margin: 0;
    font-size: 60px;
    letter-spacing: -0.04em;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 0.95;
    text-transform: uppercase;
}

.contact-email {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    line-height: 0.95;
    letter-spacing: -0.04em;
    color: #1a1a1a;
    text-align: left;
    margin-bottom: 20px;
}

.contact-description {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 0.95;
    color: #1a1a1a;
    text-align: left;
    max-width: 500px;
    letter-spacing: -0.04em;
}

.form-content {
    flex: 1;
    max-width: 600px;
}

.form-group {
    position: relative;
    margin-bottom: 30px;
}

.floating-label {
    position: absolute;
    top: 20px;
    left: 15px;
    transform: translateY(0);
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    color: #666;
    background-color: #fff;
    padding: 0 5px;
    pointer-events: none;
    transition: 0.2s ease all;
}

.input {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    width: 100%;
    padding: 15px;
    padding-top: 25px;
    border-radius: 10px;
    outline: 2px solid transparent;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    transition: outline-offset 0.25s, border-color 0.25s;
}

.styled-input {
    border-radius: 8px;
    border: 1px solid #666;
}

.input:focus {
    outline-offset: 5px;
    border-color: #000;
}

.input:focus+.floating-label,
.input:not(:placeholder-shown)+.floating-label {
    top: -10px;
    left: 10px;
    font-size: 0.875rem;
    color: #000;
}

.form-button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.form-button {
    width: 220px;
    padding: 10px;
    background-color: #FF872F;
    color: #fff;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-button:hover {
    background-color: #e07b36;
}

.button-loader {
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
}

.spinner {
    animation: spin 1s linear infinite;
    color: #fff;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Mobile Styles */
@media screen and (max-width: 768px) {
    .lets-talk-container {
        flex-direction: column;
        padding: 140px 20px 0px 20px;
        /* Adjusted padding for mobile */
        width: calc(100% - 40px);
        /* Ensure it fits within mobile screen width */
        margin: 0 auto;
        /* Center container on mobile */
        box-sizing: border-box;
    }

    .contact-info-container {
        margin-right: 0;
        margin-bottom: 20px;
        text-align: left;
        width: 100%;
    }

    .lets-talk-heading {
        margin-bottom: 15px;
        text-align: left;
    }

    .contact-email {
        margin-bottom: 15px;
        text-align: left;
    }

    .contact-description {
        font-size: 16px;
        line-height: 0.95;
        max-width: 100%;
        margin-bottom: 20px;
        text-align: left;
    }

    .form-content {
        width: 100%;
    }

    .form-group {
        margin-bottom: 20px;
    }

    .input {
        font-size: 1rem;
        padding: 10px;
    }

    .floating-label {
        top: 15px;
        left: 10px;
        font-size: 0.875rem;
    }

    .form-button-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .form-button {
        width: calc(100% - 10px);
        max-width: 600px;
        padding: 10px;
        margin-bottom: 40px;
    }

    .toastify-container {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}