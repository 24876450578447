.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    background-color: #f0f0f0;
    /* Light background color */
}

.coming-soon-text {
    font-family: 'Oswald', sans-serif;
    font-size: 80px;
    font-weight: 700;
    color: #1a1a1a;
    /* Dark gray text color */
    text-transform: uppercase;
    letter-spacing: -0.04em;
}