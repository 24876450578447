.info-container-wrapper-for-creator {
    padding: 80px 160px;
    background-color: rgba(207, 178, 157, 0.07);
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: -1;
    /* Brings this section in front */
}

.info-container-heading-for-creator {
    font-family: 'Oswald', sans-serif;
    /* Match the rest of the design */
    font-size: 46px;
    letter-spacing: -0.04em;
    font-weight: 600;
    color: #FF872F;
    /* White text for contrast */
    margin-bottom: 20px;
    /* Spacing between heading and cards */
    text-transform: uppercase;
    margin-left: 35px;
    text-align: left;

    /* Consistent style with other headings */
}

.info-container-for-creator {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 40px 20px;
    /* 7% opacity of #00B1B5 */
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .info-container-wrapper-for-creator {
        padding: 100px 0;
        /* Remove left and right padding for full width */
        margin-top: -20px;
        width: 100%;
        /* Ensure it takes the full width on mobile */
        box-sizing: border-box;
        /* Include padding in width calculation */
    }

    .info-container-heading-for-creator {
        font-size: 24px;
        /* Reduce heading size for mobile */
        text-align: left;
        /* Keep left-aligned heading */
        margin-bottom: 30px;
        margin-left: 20px;
        /* Add padding to align with bottom boxes */
    }

    .info-container-for-creator {
        display: flex;
        flex-direction: column;
        /* Stack the cards vertically */
        align-items: center;
        /* Center cards horizontally */
        width: 100%;
        /* Take the full width */
        box-sizing: border-box;
        /* Include padding in width calculation */
        padding: 0;
    }

    .info-card {
        width: calc(100% - 40px);
        /* Add left and right padding to align cards with heading */
        box-sizing: border-box;
        margin-bottom: 30px;
        /* Add spacing between cards */
    }
}