/* src/components/InfoCard/InfoCard.css */

.info-card {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.info-card-image {
    width: 60px;
    height: auto;
    margin-bottom: 20px;
}

.info-card-heading {
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    letter-spacing: -0.04em;
    line-height: 0.95;
    color: #1a1a1a;
    margin-bottom: 30px;
    font-size: 24px;
    text-transform: uppercase;
}

.info-card-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    letter-spacing: -0.04em;
    color: #333;
    font-size: 18px;
    line-height: 0.95;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .info-card {
        width: calc(90vw - 20px);
        /* Make the card almost full-width with some padding */
        max-width: none;
        /* Remove the max-width constraint for mobile */
        margin-bottom: 5vw;
        /* Add space between stacked cards */
        padding: 5vw;
        /* Make padding responsive based on viewport width */
        box-sizing: border-box;
        /* Ensure padding is included in width calculation */
    }

    .info-card-image {
        width: 15vw;
        /* Adjust icon size for mobile using viewport width */
        max-width: 50px;
        /* Limit the maximum size of the icon */
        height: auto;
        margin-bottom: 4vw;
        /* Space between image and heading */
    }

    .info-card-heading {
        font-size: 6vw;
        /* Reduce heading size for mobile */
        max-font-size: 20px;
        /* Cap the heading size */
        text-align: left;
        margin-bottom: 2vw;
        /* Add space below heading */
    }

    .info-card-text {
        font-size: 4.5vw;
        /* Adjust paragraph size for readability */
        max-font-size: 16px;
        /* Cap the text size */
        text-align: left;
        margin-top: 2vw;
        /* Space between text and heading */
    }
}